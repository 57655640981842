import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'homepage',
    loadChildren: () => import('./homepage/homepage.module').then( m => m.HomepagePageModule)
  },
  {
    path: 'checkup-step1',
    loadChildren: () => import('./checkup/checkup-step1/checkup-step1.module').then( m => m.CheckupStep1PageModule)
  },
  {
    path: 'checkup-step2',
    loadChildren: () => import('./checkup/checkup-step2/checkup-step2.module').then( m => m.CheckupStep2PageModule)
  },
  {
    path: 'rimbursime-step1',
    loadChildren: () => import('./rimbursime/rimbursime-step1/rimbursime-step1.module').then( m => m.RimbursimeStep1PageModule)
  },
  {
    path: 'rimbursime-step2',
    loadChildren: () => import('./rimbursime/rimbursime-step2/rimbursime-step2.module').then( m => m.RimbursimeStep2PageModule)
  },
  {
    path: 'rimbursime-dashboard',
    loadChildren: () => import('./rimbursime/rimbursime-dashboard/rimbursime-dashboard.module').then( m => m.RimbursimeDashboardPageModule)
  },
  {
    path: 'rimbursime-step3',
    loadChildren: () => import('./rimbursime/rimbursime-step3/rimbursime-step3.module').then( m => m.RimbursimeStep3PageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
